@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }



.poolGame {
    margin-top: get-vw(95px,1440 );
    margin-top: get-vw(40px,1440 );
    margin-left: get-vw(80px,1440 );
    margin-right: get-vw(80px,1440 );   
    position: relative;
    .foPattern{
        position: absolute;
        top: 27%;
        left: 5%;
        width: 29.231770833333332vw;
        height: 19.7265625vw;
    }

    .sectionPoolGameContainer{
        display: flex;
        margin-right: 3.2552083333333335vw;
        align-items: center;
        justify-content: end;
        .sectionPoolGameImg{
            width: get-vw(580px,1440 );
            height: get-vw(435px,1440 );    
        }
        h5{
            width: 16.27778vw;
            height: get-vw(32px,1440 );
            font-family: 'Montserrat arm';
            font-style: normal;
            font-weight: 400;
            font-size: 1.5625vw;
            line-height: 2.2135416666666665vw;
            color: #0E0023;
            margin-bottom: get-vw(25px,1440 );
        }
        p{
            width: get-vw(450px,1440 );
            height: get-vw(105px,1440 );
            font-family: 'Montserrat arm';
            font-style: normal;
            font-weight: 400;
            font-size: 0.9114583333333334vw;
            line-height: 200%;
            letter-spacing: 0.015em;
            color: #0E0023;
            opacity: 0.8;
            margin-bottom: get-vw(30px,1440 );
        }
        
        
    }
}