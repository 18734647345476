@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }


  .sectionRooms{
    position: relative;
    .sPattern{
      position: absolute;
      left: 5%;
      width: 29.231770833333332vw;
        height: 19.7265625vw;
    }
  }

 .nextRoomsBLockChange{ 
  .nextRoomsBlock {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-right: 13.5vw;
    margin-top: 2.734375vw;

    .all-rooms{
        background: none;
        border: none;
        font-family: 'Montserrat arm';
        font-style: normal;
        font-weight: 400;
        font-size: 1.0416666666666667vw;
        line-height:1.3020833333333333vw;
        color: #C68B43;
        margin-right: 0.5208333333333334vw;
        cursor: pointer;
    }
  }
}