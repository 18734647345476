body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* monserrat Arm */
@font-face {
  font-family: "Montserrat arm-Regular";
  src: url("./assets//fonts/fonter.am_montserrat-armenian/Montserratarm-Regular.otf");
}
@font-face {
  font-family: "Montserrat arm-Semibold";
  src: url("./assets//fonts/fonter.am_montserrat-armenian/Montserratarm-SemiBold.otf");
}
@font-face {
  font-family: "Montserrat arm-Bold";
  src: url("./assets//fonts/fonter.am_montserrat-armenian/Montserratarm-Bold.otf");
}
/* monserrat  */
@font-face {
  font-family: "Montserrat Regular";
  src: url("./assets//fonts//montserrat/Montserrat-Regular.otf");
}
@font-face {
  font-family: "Montserrat Semibold";
  src: url("./assets//fonts//montserrat/MontserratAlternates-SemiBold.otf");
}
@font-face {
  font-family: "Montserrat Bold";
  src: url("./assets//fonts//montserrat/Montserrat-Bold.otf");
}
@font-face {
  font-family: "Montserrat Light";
  src: url("./assets//fonts/fonter.am_montserrat-armenian/Montserratarm-Light.otf");
}

@font-face {
  font-family: "Montserrat arm-Thin";
  src: url("./assets//fonts/fonter.am_montserrat-armenian//Montserratarm-Thin.otf");
}