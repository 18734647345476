@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }


.picRes{
    width: get-vw(1440px,1440 );
    height: get-vw(381px,1440 );
    margin-bottom: 2.34375vw;
}
.sectionRestaurant{
    position: relative;
    margin-bottom: 3.859375vw;
}
.sPattern{
    position: absolute;
    top: -3%;
    width: 29.231770833333332vw;
        height: 19.7265625vw;
}

.secRestaurant{
    display: flex;
    margin-bottom: get-vw(40px,1440 );
}

.instFaceBlock {
    display: flex;
    position: absolute;
    width: get-vw(1440px,1440 );
    justify-content: space-between;
    margin-top: 7.8125vw;
}

.secResInstagram {
    text-align: center;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 600;
    font-size: get-vw(95px,1440 );
    line-height: 8.268229166666666vw;
    color: #F8F1E8;
    width: get-vw(472px,1440 );
    margin-left: get-vw(80px,1440 );
}


.secResFacebook{
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 600;
    font-size: get-vw(95px,1440 );
    line-height: 8.268229166666666vw;
    color: #F8F1E8;
    width: 32.77778vw;
    
    
}

.blockForMiddleBlock{
    display: flex;
    justify-content: center;
.btn-block{
        width: 11.002604166666666vw;
        height: 2.4088541666666665vw;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.06510416666666667vw solid #C68B43;
        cursor: pointer;
        gap: get-vw(5px,1440 );
        justify-content: center;
        button{
            font-family: 'Montserrat arm';
            font-style: normal;
            font-weight: 400;
            font-size: 0.78125vw;
            line-height: 0.9765625vw;
            text-align: center;
            color: #C68B43;
            background: none;
            border: none;
            cursor: pointer;

        }
}

}