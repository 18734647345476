@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }





.roomBoxMain{
    width: 40.364583333333336vw;
    height: 48.502604166666664vw;
    background-color: #FFF;


}
.box-title{
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 2.0833333333333335vw;
    line-height:2.5390625vw;
    color: #C68B43;
    margin-top: 2.5vw;
    margin-left: 12vw;
    margin-bottom: 3.3203125vw;
}
.iconsBlock{
    min-width: max-content;
    display: flex;
}
.box-line{
    width: 2.6041666666666665vw;
    height: 0.06944vw;
    background: #C68B43;
    border-radius: 0.13020833333333334vw;
    transform: rotate(-90deg);
    margin-top: 3.90625vw;
    margin-left: 18.75vw;
    margin-bottom: 0.5859375vw;
}
.background-overflow{
    overflow: hidden;
}

.box-background{
    width: 40.364583333333336vw;
    height: 23.046875vw;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: transform .5s ease,-webkit-transform .5s ease;
    &:hover{
        transform:  scale(1.2,1.2) ;
        
    }
}


.box-person{
    margin-left: 7.6171875vw;
    margin-right: 1.3020833333333333vw;
}

.box-guest{
    margin-right: 2.1484375vw;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9114583333333334vw;
    line-height: 0.9114583333333334vw;
    color: #0E0023;
    align-items: center;
    display: flex;
    align-items: center;
}

.box-union{
    margin-right: 0.9114583333333334vw;
}

.mm{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9114583333333334vw;
    line-height: 0.9114583333333334vw;
    color: #0E0023;
    margin-right: 2.2135416666666665vw;
    sup{
        font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9114583333333334vw;
    line-height: 0.9114583333333334vw;
    color: #0E0023;
    }
}

.box-bed{
    margin-right: 1.7vw;
    
}

.box-twoBox{
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9114583333333334vw;
    line-height: 0.9114583333333334vw;
    color: #0E0023;
    display: flex;
    align-items: center;
}
.btn-blk{
    display: flex;
    justify-content: center;
    margin-top: 3.515625vw;
    margin-left: 1vw;
.knowMore{
    width: 26.6vw;
    height: 4.078125vw;
    border: 0.06510416666666667vw #C68B43 solid;
    border-radius: 0.13020833333333334vw;
    background-color: transparent;
    color: #C68B43;
    cursor: pointer;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 0.015em;
 &:hover{
        background-color: #C68B43;
        color: #FFF;
        img{
            filter:invert(100%) sepia(1%) saturate(7%) hue-rotate(187deg) brightness(1003%) contrast(102%);
        }
    }
    
    img{
        margin-left: 0.5208333333333334vw;
       
    }
}
}