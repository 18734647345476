@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }


.SectionGarageRooms{
    position: relative;
.start{
    display: flex;
    align-items: center;
    gap: 12.208333vw;
    margin-top: 3.3203125vw;
}
.StartBlock{
    margin-bottom: 11vw;
}
.startBlock--rooms{
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9114583333333334vw;
    line-height: 0.9114583333333334vw;
    color: #000000;
    margin-left: 5.533854166666667vw;
    margin-right: 0.78125vw;
}
.startBlock--garageRooms{
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9114583333333334vw;
    line-height: 0.9114583333333334vw;
    color: #C09B5A;
    margin-left: 0.78125vw;
}

.SectionContainer {

    background: #F8F1E8;
    position: relative;
   
    &__startS {

        display: flex;
        flex-direction: column;
        align-items: center;
        &--line{
            width: get-vw(78px,1440 );
            height: get-vw(1px,1440 );
            background: #C68B43;
            border-radius: 0.13020833333333334vw;
            transform: rotate(-90deg);
            margin-bottom: get-vw(26px,1440 );
            margin-top: get-vw(78px,1440 );
            margin-right: get-vw(15px,1440 );
        }
        &--title {
            height: get-vw(49px,1440 );
            color: #C68B43;
            font-family: 'Montserrat arm';
            font-style: normal;
            font-weight: 600;
            font-size: get-vw(40px,1440 );
            line-height: get-vw(49px,1440 );
            margin-top: get-vw(34px,1440 );
            text-align: center;
            margin-bottom: get-vw(80px,1440 );
            margin-right: get-vw(5px,1440 );

        }
    }
}

.descripBlock{
    width: get-vw(1440px,1440 );
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 6.8359375vw;

    .sPattern{
        position: absolute;
        right: 7%;
        top: 4%;
        width: 29.231770833333332vw;
        height: 19.7265625vw;
    }
    &--p{
        width: 53.083333333333336vw;
        text-align: center;
        font-family: 'Montserrat arm';
        font-style: normal;
        font-weight: 400;
        font-size: 0.9114583333333334vw;
        line-height: 200%;
        text-align: center;
        letter-spacing: 0.015em;
        color: #0E0023;
        margin-bottom: 3.3203125vw;
    }
}
.roomsGarage{
    margin-bottom: 7.2265625vw;
}
}