@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }




.SecPool{
    position: relative;
    
   
    .PoolPattern{
        position: absolute;
        left: 1%;
        top: 11%;
        width: 29.231770833333332vw;
        height: 19.7265625vw;
    }

    .sectionPoolMain{
        display: flex;
        justify-content: center;
        min-width: max-content;
       
    }

    .sectionPoolImg{
        width: get-vw(636px,1440 );
        height: get-vw(403px,1440 );
        border: none;
    }
    .txt-block{
        margin-right: 0.9765625vw;
    }

    h5{
        width: 15vw;
        height: get-vw(31px,1440 );
        font-family: 'Montserrat arm';
        font-style: normal;
        font-weight: 400;
        font-size: 1.5625vw;
        line-height: 2.2135416666666665vw;
        color: #0E0023;
        margin-bottom: get-vw(25px,1440 );
  }

  p{
    width: 37vw;
    height: 18.15vw;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9114583333333334vw;
    line-height: 200%;
    letter-spacing: 0.015em;
    color: #0E0023;
    opacity: 0.8;
    margin-bottom: get-vw(30px,1440 );
}
}

.galleryBlockPool{
    width: get-vw(1440px,1440 );
    margin-top: 5.859375vw;
    display: flex;
    justify-content: center;
    padding-bottom: 13.020833333333334vw;
    .galleryPool{
        width: 82.2265625vw;
        height: 27.34375vw;
    }
    
}