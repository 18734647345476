@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }


.secionOutDoorHall{
    margin-top: get-vw(40px,1440 );
    margin-left: get-vw(80px,1440 );
    margin-right: get-vw(80px,1440 );   
    margin-bottom: get-vw(90px,1440 );
    position: relative;
    .foPattern{
        position: absolute;
        top: 30%;
        left: 9%;
        width: 29.231770833333332vw;
        height: 19.7265625vw;
    }
    .sectionOutDoorContainer {
    display: flex;
    margin-right: 3.2552083333333335vw;
    gap: 4.557291666666667vw;
    align-items: center;
    justify-content: end;

    .sectionOutDoorHallImg {
        width: get-vw(580px,1440 );
        height: get-vw(435px,1440 );
    }

    h5{
        width: get-vw(360px,1440 );
        height: get-vw(31px,1440 );
        font-family: 'Montserrat arm';
        font-style: normal;
        font-weight: 400;
        font-size: 1.5625vw;
        line-height: 2.2135416666666665vw;
        color: #0E0023;
        margin-bottom: get-vw(25px,1440 );
    }
    p{
        width: 28.38889vw;
        height: get-vw(105px,1440 );
        font-family: 'Montserrat arm';
        font-style: normal;
        font-weight: 400;
        font-size: 0.9114583333333334vw;
        line-height: 200%;
        letter-spacing: 0.015em;
        color: #0E0023;
        margin-bottom: get-vw(30px,1440 );
    }
    .btn-block{
        width: 11.002604166666666vw;
        height: 2.4088541666666665vw;
        display: flex;
        align-items: center;
        border: 0.06510416666666667vw solid #C68B43;
        cursor: pointer;
        gap: get-vw(5px,1440 );
        justify-content: center;
        button{
            font-family: 'Montserrat arm';
            font-style: normal;
            font-weight: 400;
            font-size: 0.78125vw;
            line-height: 0.9765625vw;
            text-align: center;
            color: #C68B43;
            background: none;
            border: none;
            cursor: pointer;

        }
    }
}
}