@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }





.applyUs{
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5625vw;
    line-height: 2.2135416666666665vw;
    color: #0E0023;
    margin-bottom: 1.9999125vw;
}

.name{
    width: 40.625vw;
    height: 4.427083333333333vw;
    padding-left: 1.3020833333333333vw;
    background-color: #F8F1E8;
    border: none;
    margin-bottom: 0.52083334vw;
}

.tell{
    width: 40.625vw;
    height: 4.427083333333333vw;
    padding-left: 1.3020833333333333vw;
    background-color: #F8F1E8;
    border: none;
    margin-bottom: 0.52083334vw;
}

.email{
    width: 40.625vw;
    height: 4.427083333333333vw;
    padding-left: 1.3020833333333333vw;
    background-color: #F8F1E8;
    border: none;
    margin-bottom: 0.52083334vw;
}

.message{
    width: 40.625vw;
    height: 7.8125vw;
    padding-left: 1.3020833333333333vw;
    background-color: #F8F1E8;
    border: none;
    margin-bottom: 0.6510416666666666vw;
    resize: none;
     &::placeholder{
        padding-top: 1.953125vw;
    }
}

.importantAreas{
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 0.78125vw;
    line-height: 200%;
    letter-spacing: 0.015em;
    color: #0E0023;
    opacity: 0.6;
    
}

.sendButton{
    width: 41.925vw;
    height: 4.427083333333333vw;
    background-color: #C68B43;
    border: none;
    color: #FFFFFF;
    margin-top: 0.6510416666666666vw;
    cursor: pointer;
    &:hover{
        background-color: #FFFFFF;
        color: #C68B43;
        border: 1px solid #C68B43;
    }
}