@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }


.header {
    width: get-vw(1440px,1440 );
    height: get-vw(49px,1440 );
    background: #0E0023;
    display: flex;
    justify-content: space-around;
     &__container{
        width: 93.43056vw;
        height: 3.40278vw;
        display: flex;
        justify-content: space-between;
        margin-left: 3.302083vw;

     }
    &__left {
        width: get-vw(417px,1440 );
        height: get-vw(49px,1440 );
        display: flex;
        align-items: center;
        gap:  1.171875vw;

        &__FLine {
            width: get-vw(1px,1440 );
            height: get-vw(49px,1440 );
            background: #F8F1E8;
            opacity: 0.3;
            border-radius:  get-vw(2px,1440 );
        }
        &__phone{
            height: get-vw(18px,1440 );
            width: get-vw(18px,1440 );
        }
        
        &__number {
            width: get-vw(103px,1440 );
            height: get-vw(15px,1440 );
            font-family: 'Montserrat arm';
            font-style: normal;
            font-weight: 400;
            font-size: 0.78125vw;
            line-height: 0.9765625vw;
            color: #FFFFFF;
            a {
                color: #FFFFFF;
                text-decoration: none;
            }
        }
        &__SLine {
            width: get-vw(1px,1440 );
            height: get-vw(49px,1440 );
            background: #F8F1E8;
            opacity: 0.3;
            border-radius: 0.13020833333333334vw;
        }
        &__location {
            width: get-vw(14px,1440 );
            height: get-vw(18px,1440 );
        }
        &__address {
            width: get-vw(68px,1440 );
            height: get-vw(15px,1440 );
            font-family: 'Montserrat arm';
            font-style: normal;
            font-weight: 400;
            font-size: 0.78125vw;
            line-height: 0.9765625vw;
            color: #F8F1E8;
        }
        &__TLine{
            width: get-vw(1px,1440 );
            height: get-vw(49px,1440 );
            background: #F8F1E8;
            opacity: 0.3;
            border-radius:  get-vw(2px,1440 );
        }
    }
    &__right{
        width: get-vw(350px,1440 );
        display: flex;
        align-items: center;

        &__facebook {
            width: get-vw(9.34px,1440 );
            height: get-vw(18px,1440 );
            cursor: pointer;
        }
        &__instagram {
            width: get-vw(18.01px,1440 );
            height: get-vw(18.04px,1440 );
            margin-left: get-vw(18px,1440);
            cursor: pointer;
        }
        &__Fline {
            width: get-vw(1px,1440 );
            height: get-vw(49px,1440 );
            background: #F8F1E8;
            opacity: 0.3;
            border-radius:  get-vw(2px,1440 );
            margin-left: get-vw(27px,1440);
        }
        &__language__block {
            width: get-vw(36px,1440);
            display: flex;
            margin-left: get-vw(27px,1440);  
            cursor: pointer;
            height: 3.3854166666666665vw;
            align-items: center;
            
        }
        &__language{
            width: get-vw(21px,1440 );
            height: get-vw(22px,1440 );
            font-family: Montserrat arm;
            font-style: normal;
            font-weight: 400;
            font-size: get-vw(14px,1440 );
            line-height: 160%;
            color: #F8F1E8;
        }
        &__darr {
            width: get-vw(10px,1440 );
            height: get-vw(6px,1440 );
            margin-top: get-vw(5px,1440 );
            margin-left: get-vw(13px,1440);
            padding-bottom: 0.78125vw;
        }
        &__reserve__block {
            width: get-vw(115px,1440);
            height: get-vw(33px,1440 );
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background: #C68B43;
            border-radius: get-vw(2px,1440 );
        }
        &__reserve {
            font-family: 'Montserrat arm';
            font-style: normal;
            font-weight: 400;
            font-size: get-vw(14px,1440 );
            line-height: 160%;
            text-align: center;
            letter-spacing: 0.015em;
            color: #FFFFFF;
        }
        &__login{
            font-family: 'Montserrat arm';
            font-style: normal;
            font-weight: 400;
            font-size: get-vw(14px, 1440);
            line-height: 160%;
            text-align: center;
            letter-spacing: 0.015em;
            color: #F8F1E8;
            text-decoration: none;
            margin-left: get-vw(26px,1440);
        }
        
    }
   
}

.languageHover:hover .modalLanguage{
    display: block;
}
.modalLanguage{
    display: none;
    width: get-vw(57px,1440 );
    height: get-vw(50px,1440 );
    left: 80.5vw;
    color: #FFFFFF;
    position: absolute;
    background: #DBB78A;
    border-radius:  0.13020833333333334vw;
    z-index: 1000;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
}