@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }




.pageRooms{
    background-color:  #F8F1E8;
    width: get-vw(1440px,1440 );
}