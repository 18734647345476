@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }



  .restaurantPage{
    background-color: #F8F1E8;
    width: get-vw(1440px,1440 );
}
.RestaurantMainPage{
    width: get-vw(1440px,1440 );
    height: 45.098958333333336vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .RestaurantContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: get-vw(620px,1440 );
        margin-left: 5.403645833333333vw;

        .RestContainerData{
            height: get-vw(430px,1440 );
            display: flex;
            flex-direction: column;
            justify-content: end;
        }


        h2{
            margin-bottom: 1.3020833333333333vw;
            font-family: 'Montserrat arm';
            font-style: normal;
            font-weight: 600;
            font-size: 2.0833333333333335vw;
            line-height: 2.5390625vw;
            color: #F8F1E8;
        }

        h1{
            font-family: 'Castoro';
            font-style: normal;
            font-weight: 400;
            font-size: 6.25vw;
            line-height: 7.8125vw;
            letter-spacing: -0.015em;
            color: #C68B43;
            margin-bottom: 0.953125vw;
        }
        
    }
}