@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }


  .sectionHotel{
    margin-bottom: get-vw(145px,1440 );
    position: relative;
    .fPattern{
      position: absolute;
      right: 10%;
      top: 2%;
      width: 29.231770833333332vw;
        height: 19.7265625vw;
    }
  }

  .sectionHotelMain{
    display: flex;
    margin: 0px 5.859375vw;
    justify-content: space-between;

  .sectionHotelVideo{
      width: get-vw(636px,1440 );
      height: get-vw(403px,1440 );
      border: none;
  }
  
  h5{
        width: 15vw;
        height: get-vw(31px,1440 );
        font-family: 'Montserrat arm';
        font-style: normal;
        font-weight: 400;
        font-size: 1.5625vw;
        line-height: 2.2135416666666665vw;
        color: #0E0023;
        margin-bottom: get-vw(25px,1440 );
  }
  p{
        width: 37vw;
        height: 18.15vw;
        font-family: 'Montserrat arm';
        font-style: normal;
        font-weight: 400;
        font-size: 0.9765625vw;
        line-height: 200%;
        letter-spacing: 0.015em;
        color: #0E0023;
        opacity: 0.8;
        margin-bottom: get-vw(30px,1440 );
  }
  .btn-block{
    width: get-vw(120px,1440 );
    height: get-vw(15px,1440 );
    display: flex;
    align-items: center;
    border: 1px solid #C68B43;
    padding: get-vw(6px,1440 );
    cursor: pointer;
    gap: get-vw(5px,1440 );
    justify-content: center;
    button{
        font-family: 'Montserrat arm';
        font-style: normal;
        font-weight: 400;
        font-size: 0.78125vw;
        line-height: 0.9765625vw;
        text-align: center;
        color: #C68B43;
        background: none;
        border: none;
    }
}
}