@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }

.footer{
    width: get-vw(1440px,1440 );
    height: get-vw(422px,1440 );
    bottom: 0;
    display: flex;
    justify-content: center;
    &__pattern{
        position: absolute;
        z-index: -1;
        width: get-vw(1440px,1440 );
        height: get-vw(422px,1440 );
    }
    &__container{
        width: get-vw(1259px,1440);
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__logo{
        width: get-vw(105px,1440 );
        height: get-vw(94px,1440 );
        margin-top: get-vw(26px,1440 );
        object-fit: cover;
        cursor: pointer;

    }
    &__contactUs{
        width: 9.33333vw;
        height: get-vw(20px,1440 );
        margin-top: get-vw(57px,1440 );
        font-family: 'Montserrat arm';
        font-style: normal;
        font-weight: 400;
        font-size: get-vw(16px,1440 );
        line-height: get-vw(20px,1440 );
        color: #F8F1E8;
        text-align: center;
    }
    &__reservation {
        font-family: 'Montserrat arm';
        font-style: normal;
        font-weight: 400;
        font-size: get-vw(12px,1440 );
        line-height: get-vw(15px,1440 );
        color: #F8F1E8;
        mix-blend-mode: normal;
        opacity: 0.6;
        margin-top: get-vw(22px,1440 );
        text-align: center;
        width: get-vw(161px,1440 );
        height: get-vw(15px,1440 );
    }
    &__contact-block {
        display: flex;
        margin-top: get-vw(18px,1440 );
        &__phone {
            width: get-vw(18px,1440 );
            height: get-vw(18px,1440 );
            margin-right: get-vw(11px,1440 );
            cursor: pointer;

        }
        &__number {
            
            font-family: 'Montserrat arm';
            font-style: normal;
            font-weight: 400;
            font-size: get-vw(16px,1440 );
            line-height: get-vw(20px,1440 );
            color: #FFFFFF;
            a{
                color: #FFFFFF;
                text-decoration: none;
            }
        }
    }
    &__social-block {
        margin-top: get-vw(25px,1440 );

        &__facebook {
            width: get-vw(9px,1440 );
            height: get-vw(18px,1440 );
            margin-right: get-vw(14px,1440 );
            cursor: pointer;

        }
        &__instagram {
            width: get-vw(18px,1440 );
            height: get-vw(18px,1440 );
            cursor: pointer;

        }
    }
    &__line{
        width: get-vw(1258px,1440 );
        height: get-vw(1px,1440 );
        margin-top: get-vw(29px,1440 );
        background: #FFFFFF;
        mix-blend-mode: normal;
        opacity: 0.2;
        border: 1px solid #F8F1E8;
    }
    &__aboutUs-block{
        display: flex;
        width: get-vw(1259px,1440 );
        margin-top: get-vw(15px,1440 );
        
        &__hotel {
            font-family: 'Montserrat arm';
            font-style: normal;
            font-weight: 400;
            font-size: get-vw(12px,1440 );
            line-height: get-vw(15px,1440 );
            color: #F8F1E8;
            mix-blend-mode: normal;
            opacity: 0.65;
            margin-right: get-vw(283px,1440 );
        }
        &__WeFlex {
            font-family: 'Montserrat arm';
            font-style: normal;
            font-weight: 400;
            font-size: get-vw(12px,1440 );
            line-height: get-vw(15px,1440 );
            color: #F8F1E8;
            mix-blend-mode: normal;
            opacity: 0.65;
        }
    }
}
