@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }

  
.SectionContainer {

    background: #F8F1E8;
    position: relative;
    .fPattern{
        position: absolute;
        right: 2%;
        top: 2%;
        width: 29.231770833333332vw;
        height: 19.7265625vw;
    }
    &__start {

        display: flex;
        flex-direction: column;
        align-items: center;
        &--line{
            width: get-vw(78px,1440 );
            height: get-vw(1px,1440 );
            background: #C68B43;
            border-radius: 0.13020833333333334vw;
            transform: rotate(-90deg);
            margin-bottom: get-vw(26px,1440 );
            margin-top: get-vw(78px,1440 );
            margin-right: get-vw(15px,1440 );
        }
        &--title {
            height: get-vw(49px,1440 );
            color: #C68B43;
            font-family: 'Montserrat arm';
            font-style: normal;
            font-weight: 600;
            font-size: get-vw(40px,1440 );
            line-height: get-vw(49px,1440 );
            margin-top: get-vw(34px,1440 );
            text-align: center;
            margin-bottom: get-vw(80px,1440 );
            margin-right: get-vw(5px,1440 );

        }
    }
    .nextRoomsBlock {
        display: flex;
        align-items: center;
        justify-content: end;
        margin-right: 13.3vw;
        margin-bottom: 5.989583333333333vw;
        margin-top: 2.734375vw;

        .all-rooms{
            background: none;
            border: none;
            font-family: 'Montserrat arm';
            font-style: normal;
            font-weight: 400;
            font-size: 1.0416666666666667vw;
            line-height: 1.3020833333333333vw;
            color: #C68B43;
            margin-right: 0.5208333333333334vw;
            cursor: pointer;
        }
    }
}

.sliderContainer{
    width: get-vw(1173px,1440 );
    height: get-vw(467px,1440 );
    margin-left: get-vw(133px,1440 );
    position: relative;
    .itemSlider{

        .bed{
            width: get-vw(339px,1440 );
            height: get-vw(223px,1440 );
            cursor: pointer;
        }
        width: get-vw(361px,1440 );
        height: get-vw(467px,1440 );
        display: flex;
        flex-direction: column;
        background: transparent;

        h2{
            margin-top: get-vw(33px,1440 );
            margin-bottom: get-vw(21px,1440 );
            font-family: 'Montserrat arm';
            font-style: normal;
            font-weight: 400;
            font-size: get-vw(24px,1440 );
            line-height: get-vw(34px,1440 );
            color: #0E0023;
            text-align: start;
            cursor: pointer;
        }
        
        p{
            text-align: start;
            font-family: 'Montserrat arm';
            width: 21.83333vw;
            font-style: normal;
            font-weight: 400;
            font-size: get-vw(14px,1440 );
            line-height: 200%;
            letter-spacing: 0.015em;
            color: #0E0023;
            opacity: 0.8;
            margin-bottom: get-vw(21px,1440 );
        }
        .btn-block{
            display: flex;

            &-fBtn{
                padding: 0.5208333333333334vw;
                width: 9.049479166666666vw;
                height: 2.4088541666666665vw;
                background: #C68B43;
                border-radius: 0.13020833333333334vw;
                margin-right: 1.4322916666666667vw;
                cursor: pointer;
                color: #fff;
                border: none;
                font-weight: 400;
                font-size: 0.9114583333333334vw;
            }
            
        } 
        .btn-sBlock {
            display: flex;
            align-items: center;
            border: 0.06510416666666667vw solid #C68B43;
            width: 11.002604166666666vw;
            height: 2.4088541666666665vw;
            cursor: pointer;
            justify-content: center;
            
            &:hover{
                background-color: #C68B43;
                button{
                    color: #fff;
                }
                img{
                    filter:invert(100%) sepia(1%) saturate(7%) hue-rotate(187deg) brightness(1003%) contrast(102%);
                }
            }
            &-btn{
                
                    font-family: 'Montserrat arm';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 0.78125vw;
                    line-height: 0.9765625vw;
                    color: #C68B43;
                    border: none;
                    background: none;
                    width: 6.575520833333333vw;
                    height: 0.9765625vw;
                    cursor: pointer;
                
            }
            .btn-arr-right{
                margin-left:  0.3255208333333333vw;
            }
        }   
        
    }
    

}