@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }

a{
    text-decoration: none;
}

.contactBlock{
    display: flex;
    margin-bottom: 1.953125vw;
}

.address{
    margin-right: 6.119791666666667vw;
    margin-bottom: 2.2135416666666665vw;

    .AddrTitle{
        margin-bottom: 0.9765625vw;
        font-family: 'Montserrat arm';
        font-style: normal;
        font-weight: 700;
        font-size: 1.0416666666666667vw;
        line-height: 1.3020833333333333vw;
        text-transform: uppercase;
        color: #1E1B1B;
        opacity: 0.7;
        width: 4.1015625vw;
        height: 1.3020833333333333vw;
    }
    .locationIcon{
        width: 0.9114583333333334vw;
        height: 1.171875vw;
        margin-right: 0.8463541666666666vw;
    }
    .tichina{
        font-family: 'Montserrat arm';
        font-style: normal;
        font-weight: 400;
        font-size: 1.0416666666666667vw;
        line-height: 1.3020833333333333vw;
        color: #0E0023;
    }
   
}

.social{
    

    h5{
        font-family: 'Montserrat arm';
        font-style: normal;
        font-weight: 700;
        font-size: 1.0416666666666667vw;
        line-height: 1.3020833333333333vw;
        text-transform: uppercase;
        color: #1E1B1B;
        opacity: 0.7;
        margin-bottom: 0.9765625vw;
    }
    
        text-decoration: none;
    .insta{
        margin-right: 1.171875vw;
    }
    
}


.contactWithUs{


    h5{        
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 700;
    font-size: 1.0416666666666667vw;
    line-height: 1.3020833333333333vw;
    text-transform: uppercase;
    color: #1E1B1B;
    opacity: 0.7;
    margin-bottom: 0.9765625vw;
    }

    .tel{
        margin-left: 0.7161458333333334vw;

        a{
            text-decoration: none;
            font-family: 'Montserrat arm';
            font-style: normal;
            font-weight: 400;
            font-size: 1.0416666666666667vw;
            line-height: 1.3020833333333333vw;
            color: #0E0023;
        }
    }
    .phoneTelBlock{
        margin-bottom: 1.5625vw;
    }
    .mail{
        margin-left: 0.7161458333333334vw;

        a{
            text-decoration: none;
            font-family: 'Montserrat arm';
            font-style: normal;
            font-weight: 400;
            font-size: 1.0416666666666667vw;
            line-height: 1.3020833333333333vw;
            color: #0E0023;
        }
    }
}

