@function get-vw($size, $width){
  $vw-context: $width * 0.01 * 1px;
  @return $size / $vw-context * 1vw;
  @return $size;
}


.homePage {
  width: 100vw;
  background-color: #F8F1E8;
  .swiper-pagination-horizontal{
    top: 58vw;
  }
}

.swiper {
  width: 100%;
  height: get-vw(900px,1440 );
}

.swiper-slide {
  text-align: center;
  font-size: get-vw(18px,1440 );
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}



.firstSlide{
    width: get-vw(1440px,1440 );
    height: get-vw(900px,1440 );
    display: flex;
    justify-content: center;
    background-size:cover;
}


.container {
  display: flex; 
  align-items: center;
  width: 87.43056vw;
  margin-right: 1.3020833333333333vw;

    &__sliderData {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      h1 {
        font-family: 'Montserrat arm';
        width: get-vw(335px,1440 );
        font-style: normal;
        font-weight: 600;
        font-size: get-vw(40px,1440 );
        line-height: get-vw(49px,1440 );
        color: #F8F1E8;
        margin: 0px 0px 0.78125vw 0px;
        text-align: start;
      }
      h2 {
        font-family: 'Castoro';
        font-style: normal;
        font-weight: 400;
        font-size: get-vw(144px,1440 );
        line-height: get-vw(134px,1440 );
        letter-spacing: -0.015em;
        color: #C68B43;
        width: get-vw(869px,1440 );
        margin: 0px 0px 1.4973958333333333vw 0px;
        text-align: start;
      }
      h3 {
        width: get-vw(321px,1440 );
        margin: 0px 0px 1.953125vw 0px;
        text-align: start;
        font-family: 'Montserrat arm';
        font-style: normal;
        font-weight: 600;
        font-size: get-vw(48px,1440 );
        line-height: get-vw(59px,1440 );
        color: #D4A872;
      }
      p {
        width: get-vw(580px,1440 );
        text-align: start;
        font-family: 'Montserrat arm';
        font-style: normal;
        font-weight: 275;
        font-size: get-vw(24px,1440 );
        line-height: 160%;
        margin: 0px;
        color: #F8F1E8;
      }
    }
   
    
}

.swiper-pagination-bullet{
  width: get-vw(20px,1440 );
  height: get-vw(20px,1440 );
  background:none;
  border: 0.09765625vw solid #FFFFFF;
  opacity: 0.5;
  
}

.swiper-pagination-bullet-active{
  background-color:  #FFFFFF;;
}


