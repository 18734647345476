@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }

  

.backgroundSlider{
    background-color:  #F8F1E8;
    padding: 6.510416666666667vw 8.7890625vw 6.510416666666667vw 8.7890625vw;
    position: relative;
    .swiper-pagination {
        top: 80%;
        position: absolute;
        height: 1.953125vw;
    }
    .swiper-button-prev{
        position: absolute;
        left: 4%;
        top: 50%;  
    }
    
    .swiper-button-next{
        position: absolute;
        right: 4%;
        top: 51%;
    }
    
}
.frSlideBackground{
    width: 100vw;
    height: 43.5546875vw;
    background-repeat: no-repeat;
    background-size: cover;
}


.roomGarageSlider {
    position: relative;
    

}



.btn-block-btn{
    background-color: #F8F1E8;
    display: flex;
    justify-content: center;
    .reserved{
        font-family: 'Montserrat arm';
        font-style: normal;
        font-weight: 400;
        font-size: 0.9114583333333334vw;
        line-height: 160%;
        text-align: center;
        letter-spacing: 0.015em;
        color: #FFFFFF;
        border: none;
        background: #C68B43;
        border-radius: 0.13020833333333334vw;
        padding: 1.2369791666666667vw 3.4505208333333335vw 1.2369791666666667vw 3.4505208333333335vw;
        margin-bottom: 6.510416666666667vw;
        cursor: pointer;
    }

}