@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }



.sectionOffer{
    width: get-vw(1440px,1440 );
    height: get-vw(464px,1440 );
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #0E0023;
    display: flex;
    justify-content: center;

    .sectionOfferContainer{
        display: flex;
        margin: 2.7994791666666665vw 11.5234375vw;
        width: get-vw(1000px,1440 );
        height: get-vw(382px,1440 );
        justify-content: space-between;
        align-items: center;

      
        .offerDescriptionContainer{


           h5{
                font-family: 'Montserrat arm';
                font-style: normal;
                font-weight: 400;
                font-size: 1.5625vw;
                line-height: 2.2135416666666665vw;
                color: #F8F1E8;
            }
            p{
                width: 23.98611vw;
                height: get-vw(70px,1440 );
                font-family: 'Montserrat arm';
                font-style: normal;
                font-weight: 400;
                font-size: 0.9114583333333334vw;
                line-height: 200%;
                letter-spacing: 0.015em;
                color: #F8F1E8;
                opacity: 0.8;
                margin-top: get-vw(20px,1440 );
                margin-bottom: get-vw(40px,1440 );
            }
            .reserve{
                margin: 0px;
                width: 11.002604166666666vw;
                height: 2.6692708333333335vw;
            }
    }
    }
}

.roomImg{
    width: get-vw(350px,1440 );
    height: get-vw(380px,1440 );
}