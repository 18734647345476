@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }

  
  .swiper {
    width: 100%;
    height: 100%;
    position: initial;
  }  
  .swiper-slide {
    background-color: transparent;  
  }

  .swiper-button-prev {
    position: absolute;
    top: 25%;
    left: get-vw(-80px,1440 );
    color: #C68B43;
  }

  .swiper-button-next {
    position: absolute;
    top: 25%;
    right: get-vw(-50px,1440 );
    color: #C68B43;
  }