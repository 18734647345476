@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }




.hammamBath {
    margin-top: get-vw(95px,1440 );
    position: relative;
    .thPattern{
        position: absolute;
        right: 10%;
        top: 30%;
        width: 29.231770833333332vw;
        height: 19.7265625vw;
    }


    .sectionHammamContainer {
      display: flex;
      margin: 0px 5.859375vw;
      gap: 4.557291666666667vw;
      align-items: center;
      .sectionHammamImg {
          width: get-vw(580px,1440 );
          height: get-vw(435px,1440 );
      }
  
      h5{
          width: get-vw(360px,1440 );
          height: get-vw(31px,1440 );
          font-family: 'Montserrat arm';
          font-style: normal;
          font-weight: 400;
          font-size: 1.5625vw;
          line-height: 2.2135416666666665vw;
          color: #0E0023;
          margin-bottom: get-vw(25px,1440 );
      }
      p{
          width: 28.38889vw;
          height: get-vw(105px,1440 );
          font-family: 'Montserrat arm';
          font-style: normal;
          font-weight: 400;
          font-size: 0.9114583333333334vw;
          line-height: 200%;
          letter-spacing: 0.015em;
          color: #0E0023;
          margin-bottom: get-vw(30px,1440 );
      }
     
  }
}