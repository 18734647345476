@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }



.followUs{
    width: get-vw(1440px,1440 );
    height: 51.432291666666664vw;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
        width: 68.03385416666667vw;
        height: 26.432291666666668vw;
    }
}
.startLine {
    width: 81.8359375vw;
    
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 6.184895833vw;
    margin-bottom: 5.208333vw;

    h3{
        font-family: "Montserrat arm-Thin";
        font-style: normal;
        font-weight: 275;
        font-size: get-vw(48px, 1440);
        line-height: get-vw(59px,1440);
        color: #C68B43;
        min-width: max-content;
    }

    .lLine{
        background-color: #C68B43;
        height: 1px;
        width: 13.7369791vw;
        margin-right: 2.2135416666666665vw;
    }
    .rLine{
        background-color: #C68B43;
        width: 13.7369791vw;
        height: 1px;
        margin-left: 2.2135416666666665vw;

    }
}