@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }


.sectionContactUs{
    margin: 0px 5.859375vw;
    height: 39.0625vw;
    width: 81.8359375vw;
    display: flex;
    gap: get-vw(120px,1440 );
}

.contacts{
    padding-top: 3.90625vw;
    width: 31.21875vw;
}