@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }




  .container__block{
    position: absolute;
    z-index: 11;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 1.3020833333333333vw;

    &__logo {
      width: get-vw(105px,1440 );
      height: get-vw(94px,1440 );
      margin-top: get-vw(18px,1440 );
      margin-left: 5.5vw;
      cursor: pointer;
    }
    &__menu{
      width: 87.43056vw;
      display: flex;
      list-style: none;
      margin: 1.953125vw 0px 6.510416666666667vw 5.533854166666667vw; 
      padding: 0px;
      justify-content: space-between;
      
      .navLink{
        list-style: none;
        text-decoration: none;
        font-family: 'Montserrat arm';
        font-style: normal;
        font-weight: 400;
        font-size: get-vw(16px,1440 );
        line-height: get-vw(20px,1440 );
        color: #F8F1E8;
        flex: none;
        order: 0;
        flex-grow: 0;
        border: none;
      }
      .active__link {
        padding-bottom: get-vw(11px,1440 );
        border-bottom: 0.13020833333333334vw solid #C68B43;
      }
     
    }
  }





