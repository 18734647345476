@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }





.amenities{
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5625vw;
    line-height: 2.2135416666666665vw;
    text-transform: uppercase;
    color: #0E0023;
    margin-bottom: 4.296875vw;
    
}


.amenitiesBlock{
    height: 29.296875vw;
    display: flex;
    gap: 70px;
    
}
.fdiv{
    width: 380px;
    border-bottom: 1px dashed gray;
    padding-bottom: 30px;
    margin-bottom: 2.4739583333333335vw;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    color: #0E0023;
}

.Buttondarr{
    width: 11px;
    height: 5.87px;
    color: #C68B43;
    margin-right: 16px;
    cursor: pointer;
}

.seeMore{
    border: none;
    background: transparent;
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #C68B43;
    cursor: pointer;
}