@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }



.reserve{
    width: get-vw(115px, 1440);
    height: get-vw(33px, 1440);
    background: #C68B43;
    border-radius: get-vw(2px,1440);
    font-family: 'Montserrat arm';
    font-style: normal;
    font-weight: 400;
    font-size: get-vw(14px, 1440);
    line-height: 160%;
    text-align: center;
    letter-spacing: 0.015em;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
    margin-left: get-vw(26px,1440);
}