@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }


.sectionHistory{
  position: relative;
  padding-bottom: 6.510416666666667vw;
  .fPattern{
    position: absolute;
    top: 16%;
    left: 4%;
    width: 29.231770833333332vw;
    height: 19.7265625vw;
  }



  .historyContainer{
    display: flex;
    margin: 0px 5.859375vw;
    justify-content: space-around;
    align-items: center;

    .blockForDescrip{
      height: 39.713541666666664vw;

    }

    h5{
      width: 14.908854166666666vw;
      height: 2.2135416666666665vw;
      font-family: 'Montserrat arm';
      font-style: normal;
      font-weight: 400;
      font-size: 1.5625vw;
      line-height: 2.2135416666666665vw;
      color: #0E0023;
      margin-bottom: 1.953125vw;
    }
    p{
      width: 39.914063vw;
      font-family: 'Montserrat arm';
      font-style: normal;
      font-weight: 400;
      font-size: 0.9114583333333334vw;
      line-height: 200%;
      letter-spacing: 0.015em;
      color: #0E0023;
      opacity: 0.8;
    }
    img{
      width: 40.169270833333336vw;
      height: 46.484375vw;
    }
  }
}