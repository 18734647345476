body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
}

* {
    margin: 0px;
    padding: 0px;
}