@function get-vw($size, $width){
    $vw-context: $width * 0.01 * 1px;
    @return $size / $vw-context * 1vw;
    @return $size;
  }



.secRooms{
    position: relative;
    .pPattern{
        position: absolute;
        right: 8%;
        top: 1%;
        width: 29.231770833333332vw;
        height: 19.7265625vw;
    }

    .start{
        display: flex;
        align-items: center;
        gap: 21.208333vw;
        margin-top: 3.3203125vw;
    }
    .StartBlock{
        margin-bottom: 13vw;
    }
    .startBlock--hotel{
        font-family: 'Montserrat arm';
        font-style: normal;
        font-weight: 400;
        font-size: 0.9114583333333334vw;
        line-height: 0.9114583333333334vw;
        color: #000000;
        margin-left: 5.533854166666667vw;
        margin-right: 0.78125vw;
    }
    .startBlock--Rooms{
        font-family: 'Montserrat arm';
        font-style: normal;
        font-weight: 400;
        font-size: 0.9114583333333334vw;
        line-height: 0.9114583333333334vw;
        color: #C09B5A;
        margin-left: 0.78125vw;
    }
    
    .SectionContainer {
    
        background: #F8F1E8;
        position: relative;
       
        &__startS {
    
            display: flex;
            flex-direction: column;
            align-items: center;
            &--line{
                width: get-vw(78px,1440 );
                height: get-vw(1px,1440 );
                background: #C68B43;
                border-radius: 0.13020833333333334vw;
                transform: rotate(-90deg);
                margin-bottom: get-vw(26px,1440 );
                margin-top: get-vw(78px,1440 );
                margin-right: get-vw(15px,1440 );
            }
            &--title {
                height: get-vw(49px,1440 );
                color: #C68B43;
                font-family: 'Montserrat arm';
                font-style: normal;
                font-weight: 600;
                font-size: get-vw(40px,1440 );
                line-height: get-vw(49px,1440 );
                margin-top: get-vw(34px,1440 );
                text-align: center;
                margin-bottom: get-vw(80px,1440 );
                margin-right: get-vw(5px,1440 );
    
            }
        }
    }


    .descBlock{
        margin-right: 14.523438vw;
        margin-left: 13.955729vw;
        padding-bottom: 6.510416666666667vw;
        p{
            text-align: center;
            font-family: 'Montserrat arm';
            font-style: normal;
            font-weight: 400;
            font-size: 0.9114583333333334vw;
            line-height: 200%;
            text-align: center;
            letter-spacing: 0.015em;
            color: #0E0023;
            opacity: 0.8;
        }

    }

    .room-box-blck{
        display: flex;
        margin-bottom: 0.5859375vw;
        margin-right: 5.859375vw;
         margin-left: 5.859375vw;
         gap: 1.3020833333333333vw;
         justify-content: center;

    }
    .room-sBox-blck{
        display: flex;
        padding-bottom: 9.765625vw;
        margin-right: 5.859375vw;
         margin-left: 5.859375vw;
         gap: 1.3020833333333333vw;
         justify-content: center;
    }
    
}